export const loadState = stateId => {
  try {
    const serializedState = localStorage.getItem(stateId)
    if (serializedState === null) {
      return undefined
    }

    return JSON.parse(serializedState)
  } catch (err) {
    return undefined
  }
}

export const saveState = (state, stateId) => {
  try {
    const serializedState = JSON.stringify(state)

    localStorage.setItem(stateId, serializedState)
  } catch (err) {
    return undefined
  }
}

export const clearState = stateId => {
  try {
    localStorage.removeItem(stateId)
  } catch (err) {
    return undefined
  }
}
