import Cookies from "js-cookie"

function ResolveTimezone() {
  if (Cookies.get("timezone")) {
    return
  }

  try {
    const timezoneName = Intl.DateTimeFormat().resolvedOptions().timeZone

    if (timezoneName !== null) {
      Cookies.set("timezone", timezoneName, { expires: 14 })
    }
  } catch {
    // IE11 and below, Safari < 10: No support 🤷
  }
}

export default ResolveTimezone
