export const errorsToIgnore = [
  // Ignored as part of legacy page that causes weird errors https://app.shortcut.com/ifttt/story/47191
  "YsAppBridge is not defined",

  // Mate Translate browser extension error
  "MtPopUpList is not defined",

  // Difficult-to-catch error from Google Geocoder API
  "GEOCODER_GEOCODE: ZERO_RESULTS",

  // Firefox/Safari private mode with no known consequences https://app.shortcut.com/ifttt/story/126427
  "No identifiers allowed directly after numeric literal",

  // Yandex error mostly from no-longer-supported 1Password in-app browser
  "window.yandexReadabilityChecker.isProbablyReaderable",

  // Telegram webview error from Headless Chrome (i.e. likely not an actual user)
  "window.TelegramWebview.post is not a function",

  // This is an error from Matomo ("a privacy-friendly Google Analytics alternative") outside of our control
  // https://developer.matomo.org/guides/form-analytics/reference/
  "Java bridge method invocation error",

  // This is a mysterious error that Textfit (used by Fittty) encounters with little or no user
  // experience ramifications. Our use of Textfit is now wrapped in an ErrorBoundary as well.
  // See https://app.shortcut.com/ifttt/story/126426 / https://github.com/IFTTT/web/pull/8562
  "Argument 1 ('element') to Window.getComputedStyle must be an instance of Element",
  "Window.getComputedStyle: Argument 1 is not an object",
  "Failed to execute 'getComputedStyle' on 'Window': parameter 1 is not of type 'Element'",

  // .NET embedded Chromium tool (CefSharp) used to crawl sites.
  // Details: https://trackjs.com/javascript-errors/object-not-found-matching-id-methodname-paramcount/
  "Object Not Found Matching Id",

  // This is expected to prevent Google Sign in iFrame from showing to keep all buttons consistent. See ApplicationHelper#block_google_signin_iframe
  "csp_violation: 'https://accounts.google.com' blocked by 'frame-src' directive",
  "csp_violation: 'https://accounts.google.com/' blocked by 'frame-src' directive",

  // We're using an old verion of Monaco editor. During unmount, few internal components aren't disposed properly.
  // It doesn't impact users directly. It was fixed on version 4.2+.
  // Issues related: https://github.com/microsoft/monaco-editor/issues/1638 and https://github.com/microsoft/monaco-editor/issues/1842
  "Could not find file: 'inmemory://model/1'",

  // An old WebKit bug results in these generic errors being logged to Datadog by Safari and other WebKit-based browsers even when CORS headers are configured correctly. The errors are not useful or actionable in this state.
  // https://bugs.webkit.org/show_bug.cgi?id=132945
  // https://blog.sentry.io/what-is-script-error
  "Script error.",

  // We have been able to reproduce the error locally, however it only appears in RUM and not in the local browser's developer tools, which makes it virtually impossible to troubleshoot. There is no evidence that the error caused any UX or performance issues, so it's more reasonable to ignore it at this point.
  "Uncaught \"Empty reason\"",

  // These errors have so far been observed only from malicious bots and scrapers trying to hit our website and being unable (most likely on purpose) to execute some of the JavaScript, and we don't actually want them to succeed or care about their experience when they visit us
  "grecaptcha is not defined",
  "Uncaught \"Timeout\"",
]
