import TimezoneResolver from "shared/timezone_resolver"
import { init, globalHandlersIntegration, extraErrorDataIntegration, replayIntegration } from "@sentry/react"
// datadogRum has side effects that don't work in ExecJS
import { datadogRum } from "@datadog/browser-rum-slim"
import { assignWithBustedCache } from "shared/lib/utils"
import { errorsToIgnore } from "app/config/errors_to_ignore"

import DOMPurify from "dompurify"

const stacksToIgnore = [
  // We don't have any action to do here. https://app.shortcut.com/ifttt/story/129326/not-count-as-error-on-dd-the-issues-related-with-chrome-extensions
  "chrome-extension://",
]

const interceptUpgradeFromMobileUsers = event => {
  if (!event.target.closest("header.unified-header.signed-in a[data-intercept-mobile-users]")) {
    return
  }

  event.preventDefault()
  const paymentType = event.target.dataset["interceptMobileUsers"]

  if (paymentType === "apple") {
    window.appendFlash(
      "You currently have a Pro subscription on Apple. Please upgrade to Pro+ on your iOS device.",
      "danger"
    )
  } else if (paymentType === "google") {
    window.appendFlash(
      "You currently have a Pro subscription on Google. Please upgrade to Pro+ on your Android device.",
      "danger"
    )
  }
}

export function oneTimeSetup() {
  document.addEventListener("click", interceptUpgradeFromMobileUsers)

  TimezoneResolver()
}

const shouldDiscard = event => {
  if (event.type === "error") {
    if (errorsToIgnore.some(error => event.error?.message?.includes(error))) {
      return true
    }

    if (stacksToIgnore.some(error => event.error?.stack?.includes(error))) {
      return true
    }
  }

  return false
}

export function sharedSetup() {
  window.appendFlash = function (message, type = "success", callback = null, sticky = false) {
    const fadeOutMessage = (msg) => {
      msg.classList.remove("fadein")
      msg.classList.add("fadeout")
      setTimeout(() => {
        msg.style.display = "none"
        msg.remove()
      }, 500)
    }

    // Remove existing sticky flash messages
    document.querySelectorAll("#flash-messages .sticky").forEach(existingMsg => {
      fadeOutMessage(existingMsg)
    })

    let msg = document.createElement("div")
    msg.className = `flash-msg alert alert-${type} fadein`
    if (sticky) {
      msg.classList.add("sticky")
    }
    msg.append(DOMPurify.sanitize(message))

    document.querySelector("#flash-messages").appendChild(msg)

    if (!sticky) {
      setTimeout(() => {
        fadeOutMessage(msg)
        callback?.()
      }, 3000)
    }
  }

  document.querySelectorAll(".flash-msg:not(.sticky)").forEach(el => {
    setInterval(function () {
      el.classList.add("fadeout")
      setTimeout(() => {
        el.style.display = "none"
      }, 500)
    }, 3000)
  })

  // Web isn't using experiments for now. Reducing unnecessary calls until we
  // move Experiments to Graph and reimplement the solution.
  // confirmExperimentReceipts()

  // Enable JS error reporting via Sentry
  if (window.App?.sentryDSNGrizzlyClient) {
    init({
      dsn: window.App.sentryDSNGrizzlyClient,
      release: window.App.datadogVersion,
      initialScope: {
        user: { id: window.App?.user?.id },
      },
      // replaysSessionSampleRate: 0.0, // replay sample rate when no error occurs
      // replaysOnErrorSampleRate: 1.0, // replay sample rate when an error occurs
      integrations: [
        // Most Promise libraries have a global hook for capturing unhandled errors.
        // As we don't have control over what third-party libraries use, we'll
        // turn this kind of error off.
        globalHandlersIntegration({ onunhandledrejection: false }),

        // With this integration, Sentry will inject enumerable error properties
        // for every JS Object that errs, including non-Error ones.
        extraErrorDataIntegration(),

        // As configured above, record session replays to view in Sentry:
        // https://docs.sentry.io/product/explore/session-replay/web/
        // replayIntegration()
      ],
      ignoreErrors: errorsToIgnore,
    })
  }

  if (window.App?.datadogClientToken && window.App.trackSession) {
    datadogRum.init({
      applicationId: window.App.datadogApplicationId,
      clientToken: window.App.datadogClientToken,
      site: "datadoghq.com",
      service: window.App.datadogService,

      // Specify a version number to identify the deployed version of your application in Datadog
      version: window.App.datadogVersion,
      sessionSampleRate: Number(window.App.datadogSessionSampleRate),
      // @datadog/browser-rum-slim does not include session replay feature
      // sessionReplaySampleRate: 0,
      allowedTracingUrls: window.App.datadogAllowedTracingUrls,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: "mask-user-input",
      beforeSend: (event, context) => {
        if (shouldDiscard(event)) {
          return false
        }
        // collect a RUM resource's server timing data
        if (event.type === 'resource' && (event.resource.type === 'fetch' || event.resource.type === 'document' || event.resource.type === 'xhr')) {
          let serverTiming = context.performanceEntry?.serverTiming || []
          if (serverTiming.length > 0) {
            let serverTimingEntries = serverTiming.map(
              serverEntry => [
                serverEntry.name, {"duration": serverEntry.duration, "description": serverEntry.description}
              ]
            )
            event.context.serverTiming = Object.fromEntries(serverTimingEntries)
          }
        }
      },
      proxy: options => `/dri${options.path}/p?${options.parameters}`,
    })

    if (window.App.user) {
      let user = { ...window.App.user }
      if (!user.id) {
        user.id = window.App.browserSessionId
      }
      if (!user.tier) {
        user.tier = "free"
      }
      datadogRum.setUser(user)
    }
  }

  if (window.App.mixpanelKey) {
    const MIXPANEL_PROJECT_TOKEN = window.App.mixpanelKey
    const MIXPANEL_PROXY_DOMAIN = window.location.origin
    const MIXPANEL_CUSTOM_LIB_URL = MIXPANEL_PROXY_DOMAIN + "/lib.min.js";

    let mixpanelOptions = {
      autocapture: true, // https://docs.mixpanel.com/docs/quickstart/capture-events/autocapture
      persistence: "localStorage",
      // https://docs.mixpanel.com/docs/tracking-methods/sdks/javascript#tracking-via-proxy
      api_host: MIXPANEL_PROXY_DOMAIN,
      // endpoints for different types of requests
      api_routes: {
        track: 'mxpnl/track',
        engage: 'mxpnl/engage',
        groups: 'mxpnl/groups',
        record: 'mxpnl/record',
      }
    }

    if (window.App.env === "development" || window.location.hostname === "web-rehearsal.ifttt.com") {
      mixpanelOptions.debug = true
    }

    // Load the Mixpanel JS library asyncronously via the js snippet
    (function(f,b){if(!b.__SV){var e,g,i,h;window.mixpanel=b;b._i=[];b.init=function(e,f,c){function g(a,d){var b=d.split(".");2==b.length&&(a=a[b[0]],d=b[1]);a[d]=function(){a.push([d].concat(Array.prototype.slice.call(arguments,0)))}}var a=b;"undefined"!==typeof c?a=b[c]=[]:c="mixpanel";a.people=a.people||[];a.toString=function(a){var d="mixpanel";"mixpanel"!==c&&(d+="."+c);a||(d+=" (stub)");return d};a.people.toString=function(){return a.toString(1)+".people (stub)"};i="disable time_event track track_pageview track_links track_forms track_with_groups add_group set_group remove_group register register_once alias unregister identify name_tag set_config reset opt_in_tracking opt_out_tracking has_opted_in_tracking has_opted_out_tracking clear_opt_in_out_tracking start_batch_senders people.set people.set_once people.unset people.increment people.append people.union people.track_charge people.clear_charges people.delete_user people.remove".split(" ");
    for(h=0;h<i.length;h++)g(a,i[h]);var j="set set_once union unset remove delete".split(" ");a.get_group=function(){function b(c){d[c]=function(){call2_args=arguments;call2=[c].concat(Array.prototype.slice.call(call2_args,0));a.push([e,call2])}}for(var d={},e=["get_group"].concat(Array.prototype.slice.call(arguments,0)),c=0;c<j.length;c++)b(j[c]);return d};b._i.push([e,f,c])};b.__SV=1.2;e=f.createElement("script");e.type="text/javascript";e.async=!0;e.src="undefined"!==typeof MIXPANEL_CUSTOM_LIB_URL?
    MIXPANEL_CUSTOM_LIB_URL:"file:"===f.location.protocol&&"//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js".match(/^\/\//)?"https://cdn.mxpnl.com/libs/mixpanel-2-latest.min.js":"//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js";g=f.getElementsByTagName("script")[0];g.parentNode.insertBefore(e,g)}})(document,window.mixpanel||[]);

    mixpanel.init(window.App.mixpanelKey, mixpanelOptions);

    if (window.App.user?.id) {
      // https://github.com/mixpanel/mixpanel-js/blob/master/doc/readme.io/javascript-full-api-reference.md#mixpanelidentify
      // Mixpanel: We recommend against using identify for anonymous visitors to your site.
      mixpanel.identify(window.App.user.id)
      mixpanel.people.set({
        '$name': window.App.user.id,
        '$email': window.App.userEmail,
        tier: window.App.user.tier || "free"
      })
    }

    document.querySelector("a.item[data-option='signed_out']")?.addEventListener("click", () => {
      mixpanel.reset()
    }, { once: true })
  }

  // Scrub cache-busting params from the user-facing URL
  // Example: https://github.com/IFTTT/web/blob/39386734262b01ab05386263142337e097d1b93e/app/assets/javascripts/app/components/new_user_intake/upsell.tsx#L70
  const url = new URL(window.location.href)
  const cacheBustingParams = ["🧂", "_cb"]
  const urlHasParamToRemove = cacheBustingParams.some(param => url.searchParams.has(param))

  if (urlHasParamToRemove) {
    cacheBustingParams.forEach(param => {
      if (url.searchParams.has(param)) {
        url.searchParams.delete(param)
      }
    })

    window.history.replaceState({}, "", url.pathname + url.search)
  }

  if (window.App?.user?.segment !== "unauthenticated") {
    document.querySelectorAll(`a[href='${window.location.origin}/explore']:not([data-target])`).forEach(link => {
      link.addEventListener("click", event => {
        event.preventDefault()
        assignWithBustedCache(event.target.href, event.metaKey)
      })
    })
  }

  window.addEventListener("offline", () => {
    window.appendFlash("Your network connection has been lost.", "danger", null, true)
  })

  window.addEventListener("online", () => {
    window.appendFlash("Your network connection has been restored.", "success")
  })
}

// if using this function inside a prerendered component invoke it using `window.App.Utils`
export function logCustomDatadogAction(actionName, attributes = {}) {
  if (window.App.allowDebugErrorMessages) console.log("Logging to Datadog", actionName, attributes)
  setTimeout(() => { datadogRum.addAction(actionName, attributes) })
}

export function logDatadogError(error) {
  if (window.App.allowDebugErrorMessages) console.warn("Logging error to Datadog", logDatadogError)
  setTimeout(() => { datadogRum.addError(error) })
}

export function setDatadogRumContext(key, value) {
  datadogRum.setViewContextProperty(key, value)
}
